html {
  font-size: 100%;
  height: 100%;
}

body {
  min-height: 100vh;
}

#root {
  min-height: 100vh;
  display: flex;
}
